<template>
    <app-main-wrapper>
        <app-card>
            <v-img max-height="300" :src="require('@/assets/skyline.jpg')"></v-img>
            <v-card-title>What is CityWatch?</v-card-title>
            <v-card-text>
    
    <p>
        Communities, especially those in Canada, use enormous amounts of energy, food, water, and materials. Accounting for energy and materials and their associated emissions is often, however, not straightforward. Where the oil or gas was extracted, refined, and the emissions associated with transportation need to be accounted. Where and how electricity was generated; how much was lost in transmission? Where the food was grown. 
    </p>
    <p>
        How much solid waste was generated in the community; how much of that was collected; and where was it disposed? Measuring this daily, with the ability to capture impacts of temperature, the differences between a weekday and the weekend, provides a good day-in-the-life of the community.
    </p>
    <p>
        CityWatch is an important component of Ontario Tech University's Bachelor of Arts and Science in Sustainability. Transitioning to sustainability requires an understanding of the energy and materials used within our communities, their impacts, and ways we as a community can reduce these impacts. Measuring what we need to manage.
    </p>
</v-card-text>
        </app-card>
    </app-main-wrapper>
</template>

<script>
import AppMainWrapper from "@/components/AppMainWrapper";
import AppCard from "@/components/AppCard";

export default {
    name: "About",
    components: {AppCard, AppMainWrapper}
}
</script>

<style scoped>

</style>
